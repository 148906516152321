define(["require", "exports"], function (require, exports) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    exports.Modal = void 0;
    var Modal = (function () {
        function Modal(modalSelector, options) {
            if (modalSelector === void 0) { modalSelector = '#main-modal'; }
            this._showing = false;
            this.selectors = {
                btnCancelar: '#main-modal-buttons-cancelar',
                btnConfirmar: '#main-modal-buttons-confirmar',
                modalHeader: '#main-modal-header',
                modalBody: '#main-modal-body',
                modalClose: '#main-modal-close'
            };
            if (options)
                this.SetElementIds(options);
            var modalElement = document.querySelector(modalSelector);
            var modalCloseElement = modalElement === null || modalElement === void 0 ? void 0 : modalElement.querySelector(this.selectors.modalClose);
            if (!modalElement || !modalCloseElement)
                throw new Error('Modal não foi configurada corretamente');
            this._modalElement = modalElement;
            this._modalCloseElement = modalCloseElement;
        }
        Modal.prototype.SetElementIds = function (options) {
            var btnCancelar = options.btnCancelar, btnConfirmar = options.btnConfirmar, modalHeader = options.modalHeader, modalBody = options.modalBody, modalClose = options.modalClose;
            this.selectors.btnCancelar = btnCancelar !== null && btnCancelar !== void 0 ? btnCancelar : this.selectors.btnCancelar;
            this.selectors.btnConfirmar = btnConfirmar !== null && btnConfirmar !== void 0 ? btnConfirmar : this.selectors.btnConfirmar;
            this.selectors.modalHeader = modalHeader !== null && modalHeader !== void 0 ? modalHeader : this.selectors.modalHeader;
            this.selectors.modalBody = modalBody !== null && modalBody !== void 0 ? modalBody : this.selectors.modalBody;
            this.selectors.modalClose = modalClose !== null && modalClose !== void 0 ? modalClose : this.selectors.modalClose;
            return this;
        };
        Modal.prototype.AddHeaderMessage = function (message) {
            var _a;
            if (message === void 0) { message = 'Confirmação'; }
            var element = (_a = this._modalElement
                .querySelector(this.selectors.modalHeader)) === null || _a === void 0 ? void 0 : _a.querySelector('h2');
            if (element)
                element.innerHTML = message;
            return this;
        };
        Modal.prototype.AddContent = function (content) {
            if (!content)
                throw new Error('Conteúdo vazio ao criar modal');
            var element = this._modalElement
                .querySelector(this.selectors.modalBody);
            if (element) {
                element.innerHTML = '';
                if (typeof content == "string")
                    element.innerHTML = content;
                else if (content instanceof HTMLElement)
                    element.appendChild(content);
            }
            return this;
        };
        Modal.prototype.AddConfirmButton = function (func, text) {
            if (text === void 0) { text = 'Confirmar'; }
            var buttonCancelar = document.querySelector(this.selectors.btnCancelar);
            var buttonConfirmar = document.querySelector(this.selectors.btnConfirmar);
            this._modalButtonCancelar = buttonCancelar;
            if (buttonConfirmar) {
                buttonConfirmar.innerHTML = text;
                buttonConfirmar.onclick = func;
            }
            return this;
        };
        Modal.prototype.AddCustomCancelButton = function (func, text) {
            if (text === void 0) { text = 'Cancelar'; }
            var buttonConfirmar = document.querySelector(this.selectors.btnConfirmar);
            var buttonCancelar = document.querySelector(this.selectors.btnCancelar);
            var customButton = document.createElement('button');
            customButton.innerText = text;
            customButton.onclick = func;
            customButton.className = 'ml-2 px-6 py-2 bg-gray-400 text-black focus:outline-none';
            if (buttonConfirmar && buttonCancelar) {
                buttonConfirmar.after(customButton);
                buttonCancelar.remove();
            }
            return this;
        };
        Modal.prototype.Close = function () {
            if (this._showing)
                this._modalCloseElement.dispatchEvent(new Event('click'));
        };
        Modal.prototype.Show = function (display, beforeClose, afterClose) {
            if (display === void 0) { display = 'block'; }
            if (beforeClose === void 0) { beforeClose = null; }
            if (afterClose === void 0) { afterClose = null; }
            if (!this._modalElement || !this._modalCloseElement)
                throw new Error('Modal não configurada corretamente');
            this.CloseEvent(false, beforeClose, afterClose);
            this._modalElement.style.display = display;
            this._showing = true;
        };
        Modal.prototype.CloseEvent = function (unregister, beforeClose, afterClose) {
            var _this = this;
            var _a;
            if (unregister === void 0) { unregister = false; }
            if (beforeClose === void 0) { beforeClose = null; }
            if (afterClose === void 0) { afterClose = null; }
            var closeCallback = function () {
                if (beforeClose)
                    beforeClose();
                _this._modalElement.style.display = 'none';
                if (afterClose)
                    afterClose();
            };
            var func = function (e) {
                e.preventDefault();
                closeCallback();
            };
            var windowFunc = function (e) {
                if (e.target == _this._modalElement) {
                    e.preventDefault();
                    closeCallback();
                }
            };
            if (unregister) {
                this._modalCloseElement.removeEventListener('click', func);
                (_a = this._modalButtonCancelar) === null || _a === void 0 ? void 0 : _a.removeEventListener('click', func);
                window.removeEventListener('click', windowFunc);
                return;
            }
            this._modalCloseElement.onclick = func;
            if (this._modalButtonCancelar)
                this._modalButtonCancelar.onclick = func;
            window.onclick = windowFunc;
        };
        return Modal;
    }());
    exports.Modal = Modal;
});
